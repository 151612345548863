import React from 'react';

const OverlayCoping = () => {
    return (
        <div id="copyOverlay" className="overlay">
            <div className="flex h100">
                <div className="flex-col-center">
                    <div id="loader" className="loader"></div>
                </div>
            </div>
        </div>
    );
};

export default OverlayCoping;
