import React from 'react';

const QuestionCard = (key, question, index, sended, onChanged, onSend) => {
    const handleSendButtonClick = () => {
        onSend(question.record_key);
    };

    const maxLength = 14;

    return (
        <div className="question" key={key}>
            <div className="flex-col">
                <div className="question-label">Q{index + 1}</div>
                <div className="divider"></div>
                <div className="question-content">
                    <div className="words flex-row">
                        {question.words.map((word, index) => <div className="word" key={index}>{word}</div>)}
                    </div>
                    <div className="flex">
                        <input
                            type="text"
                            className="answer-input"
                            maxLength={maxLength}
                            placeholder={`${maxLength}文字以内`}
                            disabled={sended}
                            onChange={e => { onChanged(question.record_key, e.target.value); }}
                        />
                        <button className="answer-button" data-question-id={question.record_key} onClick={handleSendButtonClick} disabled={sended}>送信</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuestionCard;
