import Constants from './Constants';
import Util from "./Util";

const Api = {
    fetchGames: async function (period) {
        const baseUrl = `${Constants.API_URL}/games`
        const url = new URL(baseUrl);
        const params = new URLSearchParams();
        if (period) {
            params.append("p", period);
        }
        url.search = params.toString();

        const response = await fetch(url.toString());
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const json = await response.json();
        return json;
    },

    fetchGame: async function (gameId) {
        const baseUrl = `${Constants.API_URL}/games/${gameId}`
        const url = new URL(baseUrl);
        const response = await fetch(url.toString());
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const json = await response.json();
        return json;
    },

    postGame: async function () {
        const baseUrl = `${Constants.API_URL}/games`
        const url = new URL(baseUrl);
        const requestBody = {
            user_id: Util.getUserId(),
        };
        const params = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        }

        const response = await fetch(url, params);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const json = await response.json();
        return json;
    },

    copyGame: async function (originalId) {
        const baseUrl = `${Constants.API_URL}/games/${originalId}`
        const url = new URL(baseUrl);
        const requestBody = {
            user_id: Util.getUserId(),
            original_id: originalId,
        };
        const params = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        }

        const response = await fetch(url, params);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const json = await response.json();
        return json;
    },

    sendAnswer: async function (gameId, questionId, answer) {
        const baseUrl = `${Constants.API_URL}/games/${gameId}/questions/${questionId}`
        const url = new URL(baseUrl);
        const requestBody = {
            user_id: Util.getUserId(),
            question_id: questionId,
            answer: answer,
        };
        const params = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        }

        // レスポンスがタイムアウトになるので投げっぱなしにする
        try {
            await fetch(url, params);
        } catch (e) {
            console.log('fetch error');
        }
    },
}

export default Api;
