import React from 'react';

const MessageCard = (message) => {
    return (
        <div className="flex">
            <div id="message" className="message">{message}</div>
        </div>
    );
};

export default MessageCard;
