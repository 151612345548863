import React from 'react';

const OverlayScoring = () => {
    return (
        <div id="overlay" className="overlay">
            <div className="flex h100">
                <div className="flex-col-center">
                    <div id="loader" className="loader"></div>
                    <div className="loading-message">採点中...</div>
                    <div className="loading-message">しばらくお待ちください</div>
                </div>
            </div>
        </div>
    );
};

export default OverlayScoring;
