import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import Header from '../components/Header';
import Footer from '../components/Footer';

const AuthPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [user, setUser] = useState(null);

    const signIn = async () => {
        try {
            const signedInUser = await Auth.signIn(username, password);
            console.log('Signed in user:', signedInUser);
            setUser(signedInUser);
        } catch (error) {
            console.error('Error signing in', error);
        }
    };

    const signOut = async () => {
        try {
            await Auth.signOut();
            console.log('User signed out');
            setUser(null);
        } catch (error) {
            console.error('Error signing out', error);
        }
    };

    return (
        <div>
            <Header />
            <hr></hr>
            <div className="flex">
                <div className="flex-col-center">
                    <h2>ログイン</h2>
                    <div className='login'>
                        {!user ? (
                            <>
                                <div className='row'>
                                    <div className='label'>
                                        <label>ID: </label>
                                    </div>
                                    <div>
                                        <input
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='label'>
                                        <label>パスワード: </label>
                                    </div>
                                    <div>
                                        <input
                                            type="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='input'>
                                    <button className='sign-in' onClick={signIn}>ログインする</button>
                                </div>
                            </>
                        ) : (
                            <>
                                <p>ようこそ {user.username}さん</p>
                                <div className='input'>
                                    <button className='sign-out' onClick={signOut}>ログアウトする</button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuthPage;
