import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Api from '../scripts/Api';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Loader from '../components/Loader';
import QuestionCard from '../components/QuestionCard';
import QuestionCardAnswered from '../components/QuestionCardAnswered';
import OverlayCoping from '../components/OverlayCoping';
import OverlayDescription from '../components/OverlayDescription';
import OverlayScoring from '../components/OverlayScoring';
import MessageCard from '../components/MessageCard';

const GamePage = () => {
    const { gameId } = useParams();

    const [game, setGame] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isCopingVisible, setCopingVisiblity] = useState(false);
    const [isDescriptionVisible, setDescriptionVisiblity] = useState(false);
    const [isScoringVisible, setScoringVisiblity] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [sendedQuestions, setSendedQuestions] = useState([]);
    const [copiedGameId, setCopiedGameId] = useState(null);
    const [answers, setAnswers] = useState([]);
    const [isFinished, setFinished] = useState(false);

    const waitingLimit = 60;
    const pollingInterval = 2;

    useEffect(() => {
        async function showGame() {
            Api.fetchGame(gameId).then(
                data => {
                    setGame(data);
                    setFinished(data.game.finished);
                    setSendedQuestions(data.questions.filter(q => q.answered).map(q => q.record_key))
                    setLoading(false);
                }
            ).catch(error => {
                console.error("There was an error fetching the games:", error);
                setLoading(false);
            });
        };

        showGame();
    }, [gameId]);

    useEffect(() => {
        setScoringVisiblity(!isFinished && game && (new Set(sendedQuestions).size === game.questions.length));
    }, [game, sendedQuestions, isFinished]);

    // 終了チェック
    useEffect(() => {
        if (isFinished) return;
        if (!isScoringVisible) return;

        var intervalId;
        var waitingSeconds = 0;

        const fetchGame = async () => {
            Api.fetchGame(gameId).then(
                data => {
                    if (data.game.finished) {
                        setFinished(true);
                        window.location.reload();
                        return;
                    }
                }
            ).catch(error => {
                console.error("There was an error fetching the games:", error);
            });
            waitingSeconds += pollingInterval;
            if (waitingSeconds > waitingLimit && intervalId) {
                setScoringVisiblity(false);
                setErrorMessage('ERROR: エラーが発生しました。ページをリロードしてください。');
                clearInterval(intervalId);
            }
        }

        intervalId = setInterval(fetchGame, pollingInterval * 1000);

        return () => clearInterval(intervalId);
    }, [isScoringVisible, gameId, isFinished]);

    const handleCopyButtonClick = () => {
        onClickCopy();
    };

    async function onClickCopy() {
        setCopingVisiblity(true);
        Api.copyGame(gameId).then(
            data => {
                const copiedGameId = data['gameId'];
                setCopiedGameId(copiedGameId);
                let url = `../games/${copiedGameId}`;
                window.open(url, '_blank');
                setCopingVisiblity(false);
            }
        ).catch(error => {
            console.error("There was an error fetching the game:", error);
            setCopingVisiblity(false);
            setErrorMessage(error.message);
        });
    }

    const handleInfoButtonClick = () => {
        setDescriptionVisiblity(true);
    };

    const handleInfoClose = () => {
        setDescriptionVisiblity(false);
    };

    const copyButton =
        <div><button id="copyButton" className="copy-button" disabled={loading} onClick={handleCopyButtonClick}>問題をコピー（対戦用）</button></div>
    const infoButton =
        <button id="infoButton" className="info-button" onClick={handleInfoButtonClick}>ℹ️<u>対戦のやり方</u></button>
    const copyLink =
        <div><a id="copyLink" href={`../games/${copiedGameId}`} target="_blank" rel="noreferrer">コピーした問題を開く</a></div>

    const buildTotalScoreCard = (score) => {
        return <div className="flex-row">
            <div className="flex">総スコア：</div>
            <div className={`flex score score${scoreRank(score)}`}>{game.game.score_sum}</div>
        </div>
    }
    const scoreRank = (score) => Math.ceil(score / 20);

    const description =
        <div className="question-text">5つの単語"すべて"と関連が深い単語を考えよう！（全4問）</div>

    return (
        <div>
            <Header />
            <div>
                {isCopingVisible ? <OverlayCoping /> : null}
                {isDescriptionVisible ? OverlayDescription(handleInfoClose) : null}
                {isScoringVisible ? <OverlayScoring /> : null}
                {errorMessage !== '' ? MessageCard(errorMessage) : null}
            </div>
            <div className="flex-col-center">
                <div className="container">
                    {loading ? null : <div className="flex-col-center">
                        {copyButton}
                        {infoButton}
                        {copiedGameId ? copyLink : null}
                    </div>
                    }
                </div>
                <div id="content">
                    {loading ? <Loader /> : null}
                    {loading ? null :
                        <div className="questions flex-col">
                            {game.game.finished ? buildTotalScoreCard(game.game.score_sum) : description}
                            {game.questions.map((question, index) => {
                                const key = question.record_key;
                                if (question.answered) {
                                    return QuestionCardAnswered(question.record_key, question, index);
                                } else {
                                    const sended = sendedQuestions.includes(key);
                                    const onChanged = (questionId, value) => {
                                        setAnswers({ ...answers, [questionId]: value })
                                    };
                                    const onSend = (questionId) => {
                                        const answer = answers[questionId];
                                        if (answer) {
                                            setSendedQuestions([...sendedQuestions, questionId]);
                                            Api.sendAnswer(gameId, questionId, answer);
                                        }
                                    };
                                    return QuestionCard(key, question, index, sended, onChanged, onSend);
                                }
                            })}
                        </div>
                    }
                </div>
            </div>
            <Footer withBack="true" />
        </div >
    );
};

export default GamePage;
