import React from 'react';

const Footer = (props) => {
    const withBack = props.withBack == "true";

    var backHandler = () => {
        let url = `../`;
        window.open(url, '_self');
    }

    return (
        <footer>
            {withBack ? <div className="flex"><button id="backButton" className="back-button" onClick={backHandler}>戻る</button></div> : null}
            <hr></hr>
            <div className="footer-link">
                <br /><a href="./privacy">プライバシーポリシー</a>
                <br /><a href="https://forms.gle/896X6XtgJxhZzbGu9">お問い合わせ</a>
                <br /><br />
            </div>
        </footer>
    );
};

export default Footer;
