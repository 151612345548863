import React from 'react';

const QuestionCardAnswered = (key, question, index) => {
    const scores = JSON.parse(question.scores);
    const scoreRank = Math.ceil(question.score_sum / 5)
    return (
        <div className="question" key={key}>
            <div className="flex-col">
                <div className="question-label">Q{index + 1}</div>
                <div className="divider"></div>

                <div className="result">
                    <div className="result-total flex-row">
                        <div className="label">A：</div>
                        <div className="answer">{question.answer}</div>
                        <div className={`score-detail flex score${scoreRank}`}>{question.score_sum}</div>
                    </div>
                    <div className="result-words flex-col">
                        <div className="result-word">
                            {scores.map((score, index) => {
                                return <div key={index}>
                                    <div className="flex-row">
                                        <div className="word">{score.word}</div>
                                        <div className="score-label">スコア:</div>
                                        <div className="word-score">{score.score}</div>
                                    </div>
                                    <div className="reason">{score.reason}</div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuestionCardAnswered;
