import React from 'react';

const OverlayDescription = (onClose) => {
    const handleClose = () => {
        onClose.call();
    };

    return (
        <div id="infoOverlay" className="overlay" onClick={handleClose}>
            <div className="flex">
                <div className="info-base flex-col-center">
                    <div className="info-text"><u>やり方</u></div>
                    <div className="info-text">今表示しているページを対戦相手に共有します。</div>
                    <div className="triangle-down"></div>
                    <div className="info-text">対戦相手は「問題をコピー」してコピー先に移動します。</div>
                    <div className="triangle-down"></div>
                    <div className="info-text">これで２人が同じ問題で遊ぶことができます。スコアで勝負しよう！</div>
                    <div className="info-text warn">注：AIの性質上、同じ回答でもスコアが異なる場合があります。</div>
                    <div className="info-text"><u>楽しむコツ</u></div>
                    <div className="info-text">AIが採点するため、表現を工夫するとちょっとズルい方法でハイスコアを出すことができます。<br></br>「文章禁止」「人名縛り」など、自分たちでルールを決めてより面白く遊ぼう！</div>
                    <div></div>
                </div>
            </div>
        </div>
    );
};

export default OverlayDescription;
