import React from 'react';

const OverlaySummary = (onClose) => {
    const handleClose = () => {
        onClose.call();
    };

    return (
        <div id="infoOverlay" className="overlay" onClick={handleClose}>
            <div className="flex">
                <div className="info-base flex-col-center">
                    <div className="info-text"><u>どんなゲーム？</u></div>
                    <div className="info-text">お題となる5つの単語から連想される単語を答えよう！</div>
                    <div className="info-text">各単語との関連が強いほど高得点！発想力、語彙力、知識などが問われるよ！</div>
                    <div className="info-text">1ゲームに4問あるよ！各25点で合計100点満点！（でも60点くらいで高得点）</div>
                    <div className="info-text"></div>
                    <div className="info-text">みんなでワイワイ楽しむゲームなので、動画や配信でのプレイも大歓迎！</div>
                    <div></div>
                </div>
            </div>
        </div>
    );
};

export default OverlaySummary;
