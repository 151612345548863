import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { Auth } from 'aws-amplify';
import HomePage from './pages/HomePage';
import GamePage from './pages/GamePage';
import AuthPage from './pages/AuthPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';

const PrivateRoute = ({ element: Component, ...rest }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  useEffect(() => {
    checkAuthState();
  }, []);

  const checkAuthState = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      setIsAuthenticated(true);
    } catch (e) {
      console.error(e);
    } finally {
      setIsAuthenticating(false);
    }
  };

  return isAuthenticated ? (
    Component
  ) : isAuthenticating ? (
    <div>Loading...</div>
  ) : (
    <Navigate to="/auth" replace />
  );
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/auth" element={<AuthPage />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/games/:gameId" element={<GamePage />} />
        <Route path="/p" element={<PrivateRoute element={<HomePage mode="p" />} />} />
        <Route path="/p/games/:gameId" element={<PrivateRoute element={<GamePage />} />} />
        <Route path="/privacy" element={<PrivacyPolicyPage />} />
      </Routes>
    </Router>
  );
};

export default App;
