import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Api from '../scripts/Api';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Loader from '../components/Loader';
import GameCard from '../components/GameCard';
import OverlayLoader from '../components/OverlayLoader';
import OverlaySummary from '../components/OverlaySummary';
import MessageCard from '../components/MessageCard';

const HomePage = (props) => {
    const [games, setGames] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isOverlayVisible, setOverlayVisibility] = useState(false);
    const [isSummaryVisible, setSummaryVisiblity] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const location = useLocation();

    useEffect(() => {
        showRanking();
    }, []);

    useEffect(() => {
        setOverlayVisibility(false);
    }, [location]);

    async function showRanking() {
        setLoading(true);
        Api.fetchGames().then(
            data => {
                setGames(data);
                setLoading(false);
            }
        ).catch(error => {
            console.error("There was an error fetching the games:", error);
            setErrorMessage(error);
            setLoading(false);
        });;
    };

    const handleStartButtonClick = () => {
        onClickCreate();
    };

    async function onClickCreate() {
        setOverlayVisibility(true);
        Api.postGame().then(
            data => {
                const gameId = data['gameId'];
                let url = `games/${gameId}`;
                window.open(url, '_self');
            }
        ).catch(error => {
            console.error("There was an error fetching the games:", error);
            setErrorMessage(error);
            setOverlayVisibility(false);
        });;
    }

    const handleSummaryButtonClick = () => {
        setSummaryVisiblity(true);
    };
    const handleSummaryClose = () => {
        setSummaryVisiblity(false);
    };
    const summaryButton =
        <button id="infoButton" className="info-button" onClick={handleSummaryButtonClick}>ℹ️<u>どんなゲーム？</u></button>


    return (
        <div>
            <Header mode={props.mode} />
            <div>
                {isOverlayVisible ? OverlayLoader() : null}
                {isSummaryVisible ? OverlaySummary(handleSummaryClose) : null}
                {errorMessage !== '' ? MessageCard(errorMessage) : null}
                {loading ? null : <div className="flex-col-center">
                    {summaryButton}
                </div>
                }
                <div id="starButtonContainer">
                    <button id="startButton" className="button" onClick={handleStartButtonClick}>
                        ゲームを始める
                    </button>
                </div>
                <hr />
                <div className="flex">
                    <div className="flex-col">
                        <div className="ranking-title">みんなの結果（新規順）</div>
                    </div>
                </div>
                <div id="container" className="container">
                    <div id="content">
                        {loading ? <Loader /> : games.map((game, index) => GameCard(game.game_id, game, index + 1))}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default HomePage;
