import React from 'react';

const Loader = () => {
    return (
        <div className="flex">
            <div id="loader" className="loader flex"></div>
        </div>
    );
};

export default Loader;
