import React from 'react';

const OverlayLoader = () => {
    return (
        <div id="overlay" className="overlay">
            <div className="flex h100">
                <div className="flex-col-center">
                    <div id="loader" className="loader"></div>
                </div>
            </div>
        </div>
    );
};

export default OverlayLoader;
