const Util = {
    formatDate: function (date) {
        const yyyy = date.getFullYear();
        const mm = String(date.getMonth() + 1).padStart(2, '0'); // 月は0から11までのインデックスで取得されるため+1
        const dd = String(date.getDate()).padStart(2, '0');
        const hh = String(date.getHours()).padStart(2, '0');
        const mi = String(date.getMinutes()).padStart(2, '0');

        return `${yyyy}-${mm}-${dd} ${hh}:${mi}`;
    },

    getUserId: function () {
        let uuid = localStorage.getItem('uuid');
        if (!uuid) {
            uuid = this.generateUUID();
            localStorage.setItem('uuid', uuid);
        }
        return uuid;
    },

    generateUUID: function () {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0,
                v = c === 'x' ? r : ((r & 0x3) | 0x8);
            return v.toString(16);
        });
    }
};

export default Util;
