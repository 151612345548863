import React from 'react';
import Util from '../scripts/Util';
import { Link } from 'react-router-dom';

const GameCard = (key, game, rank) => {
    const scores = JSON.parse(game.scores);
    const id = game.user_id.substring(0, 12);
    const date = Util.formatDate(new Date(game.finished_at));

    return (
        <Link to={`/games/${game.game_id}`} style={{ textDecoration: 'none' }} key={key}>
            <div className="item flex" data-game-id={game.game_id}>
                <div className="rank flex">{rank}</div>
                <div className={`score flex score${Math.ceil(game.score_sum / 20)}`}>{game.score_sum}</div>
                <div className="info flex-col">
                    <div className="score-details flex-row">
                        {scores.map((score, i) => {
                            return <div className={`score-detail flex score${Math.ceil(score / 5)}`} key={i}>{score}</div>
                        })}
                    </div>
                    <div className="flex-row">
                        <div className="date">{date}</div>
                        <div className="user">({id})</div>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default GameCard;
