

import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const PrivacyPolicyPage = () => {
    return (
        <div>
            <Header />
            <div className="privacy">
                <h2>プライバシーポリシー</h2>

                <h3>1. はじめに</h3>
                <p>このプライバシーポリシーは、AnoTango（以下、「当サイト」という）が提供するサービス（以下、「本サービス」という）をご利用いただく際の、個人情報およびプライバシーに関する当サイトの方針を説明するものです。</p>

                <h3>2. 収集する情報</h3>
                <p>当サイトは、アカウント登録を必要としないため、名前、メールアドレス、住所などの個人を特定する情報の収集は行いません。ただし、サービスの品質向上のために、利用者のIPアドレス、ブラウザの種類、アクセス時間などの非個人情報を自動的に収集することがあります。</p>

                <h3>3. 情報の使用目的</h3>
                <p>収集した非個人情報は以下の目的で使用されます：
                    <br /><br />
                    - サービスの品質向上<br />
                    - 不正行為の防止<br />
                    - 統計的分析<br />
                </p>

                <h3>4. 情報の共有と開示</h3>
                <p>当サイトは、収集した情報を第三者と共有することはありません。ただし、法的要求に基づく場合や、当サイトの権利や財産を守るために必要と判断される場合は、情報を開示することがあります。</p>

                <h3>5. クッキーの使用</h3>
                <p>当サイトでは、ユーザーエクスペリエンスの向上のためにクッキーを使用することがあります。クッキーは任意で無効にすることが可能ですが、一部の機能が利用できなくなる可能性があります。</p>

                <h3>6. プライバシーポリシーの変更</h3>
                <p>当サイトは、必要に応じてプライバシーポリシーを更新することがあります。変更があった場合は、当サイト上で通知いたします。</p>

                <h3>7. お問い合わせ</h3>
                本ポリシーに関するご質問や懸念がある場合は、ページ下部の「お問い合わせ」からご連絡ください。
            </div>
            <Footer withBack="true" />
        </div>
    );
};

export default PrivacyPolicyPage;
