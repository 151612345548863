import React from 'react';

const Header = (props) => {
    return (
        <header>
            <div className="subtitle flex">【AI単語連想ゲーム】</div>
            <div className="inline flex">
                <div className="flex-col">
                    <div className="flex-col">
                        <a href="/" className="logo flex">AnoTango</a>
                        <div className="subtitle2">———  あのたんご ———</div>
                        {/* <div className='mode'>
                            {props.mode == 'p'
                                ? <div className="premium">高精度版</div>
                                : <div className="free">無料版</div>
                            }
                        </div> */}
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
